import React, { useState, useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageContext } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { ProjectsDesktop } from "../components/projects/projects-desktop";
import { ProjectsMobile } from "../components/projects/projects-mobile";

const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  min-height: ${(props) => props.height}px;

  & .column {
    grid-column: span 1;

    height: 100%;
    width: 50vw;

    &.left {
      padding: 80px 30px 30px 30px;

      & .single-project-image {
        display: none;
        padding: 40px 0 0 0;

        &.active {
          display: block;
        }

        & img {
          max-height: calc(${(props) => props.height}px - 110px - 110px);
          height: 100%;
          width: 100%;

          object-fit: contain;
        }
      }
    }

    &.right {
      background-color: #dde1d4;
      padding: 110px 30px 30px 30px;

      & .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        width: 100%;

        & button {
          &.is-active {
            font-family: "Selecta Bold", sans-serif;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              font-family: "Selecta Bold", sans-serif;
            }
          }
        }

        &.visible {
          display: flex;
        }

        &.hidden {
          display: none;
        }
      }
    }

    & .single-project-container {
      & a {
        display: grid;
        grid-template-columns: auto 80px;

        width: 100%;

        font-size: 24px;
        line-height: 30px;

        & .year {
          text-align: right;
          font-feature-settings: "tnum";
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            font-family: "Selecta Bold", sans-serif;
          }
        }

        @media (max-width: 1000px) {
          font-size: 17px;
          line-height: 125%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;

    background-color: ${(props) => props.backgroundColor};

    & .mobile-view-container {
      padding: 50px 15px 0 15px;

      & .spacer {
        margin: 0 5px;
      }

      & button {
        &.active {
          font-family: "Selecta Bold", sans-serif;
        }
      }
    }

    & .column {
      grid-column: unset;
      width: 100%;

      &.left {
        display: none;
      }

      &.right {
        padding: 15px 15px 50px 15px;
        background-color: ${(props) => props.backgroundColor};
      }
    }
  }
`;

const Projects = ({ data }) => {
  const height = use100vh();
  const [pageContext, setPageContext] = useContext(PageContext);
  const [activeImage, setActiveImage] = useState(null);
  const [activeSort, setActiveSort] = useState(`-year`);
  const [activeView, setActiveView] = useState(`list`);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    setPageContext({
      pageTitle: `Projects`,
      pageColor: `#DDE1D4`,
      pageType: `projects`,
    });
  }, []);

  const allImages = data.allPrismicProject.nodes.map((project, index) => (
    <div
      className={`single-project-image ${
        activeImage === index ? `active` : ``
      }`}
      key={`single_project_image_${index}`}
    >
      {project.data.thumbnail !== null && (
        <img
          srcSet={project.data.thumbnail.fluid.srcSet}
          src={project.data.thumbnail.fluid.src}
          alt={project.data.thumbnail.alt}
        />
      )}
    </div>
  ));

  return (
    <>
      <PageSeo
        seoTitle={`Projects - Jamie Whelan Studio`}
        seoText={null}
        seoImage={null}
      />

      <Page
        height={height}
        backgroundColor={activeView === `list` ? `#dde1d4` : `#fff`}
      >
        <div className="column left">{allImages}</div>

        {!isDesktop && (
          <div className="mobile-view-container">
            <button
              onClick={() => setActiveView(`list`)}
              className={activeView === `list` ? `active` : ``}
            >
              List
            </button>
            <span className="spacer">/</span>
            <button
              onClick={() => setActiveView(`image`)}
              className={activeView === `image` ? `active` : ``}
            >
              Image
            </button>
          </div>
        )}

        <div className="column right">
          <div
            className={`filters ${
              activeView === `list` ? `visible` : `hidden`
            }`}
          >
            <button
              className={`filter by-project ${
                activeSort === `title` || activeSort === `-title`
                  ? `is-active`
                  : ``
              }`}
              onClick={() =>
                setActiveSort(activeSort === "title" ? "-title" : "title")
              }
            >
              {activeSort === `title` && `↓`}
              {activeSort !== `title` && activeSort !== `-title` && `↓`}
              {activeSort === `-title` && `↑`}
            </button>
            <button
              className={`filter by-year ${
                activeSort === `year` || activeSort === `-year`
                  ? `is-active`
                  : ``
              }`}
              onClick={() =>
                setActiveSort(activeSort === "year" ? "-year" : "year")
              }
            >
              {activeSort === `year` && `↓`}
              {activeSort !== `year` && activeSort !== `-year` && `↓`}
              {activeSort === `-year` && `↑`}
            </button>
          </div>

          {isDesktop ? (
            <ProjectsDesktop
              data={data}
              activeSort={activeSort}
              activeImage={activeImage}
              setActiveImage={setActiveImage}
            />
          ) : (
            <ProjectsMobile
              data={data}
              activeSort={activeSort}
              activeView={activeView}
            />
          )}
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Projects);

export const query = graphql`
  {
    allPrismicProject(sort: { fields: data___year, order: DESC }) {
      nodes {
        url
        data {
          title {
            text
          }
          year
          thumbnail {
            alt
            fluid {
              srcSet
              src
            }
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;
