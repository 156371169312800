/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = (property) => {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === "title") {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.data.title.text.localeCompare(a.data.title.text);
      } else {
        return a.data.title.text.localeCompare(b.data.title.text);
      }
    };
  }

  if (property === "year") {
    return function (a, b) {
      if (sortOrder === -1) {
        return b.data.year - a.data.year;
      } else {
        return a.data.year - b.data.year;
      }
    };
  }
};
