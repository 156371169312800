import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";

// Utils
import { dynamicSort } from "../utils/dynamic-sort";

export const ProjectsDesktop = ({
  data,
  activeSort,
  activeImage,
  setActiveImage,
}) => {
  const allProjects = data.allPrismicProject.nodes
    .sort(dynamicSort(activeSort))
    .map((project, index) => (
      <li
        className="single-project-container"
        key={`single_project_${index}`}
        onMouseOver={() => {
          if (activeImage !== index) setActiveImage(index);
        }}
        onMouseOut={() => {
          setActiveImage(null);
        }}
      >
        <Link to={project.url}>
          <span className="title">{project.data.title.text}</span>
          <span className="year">{project.data.year}</span>
        </Link>
      </li>
    ));

  return <ol>{allProjects}</ol>;
};
