import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Utils
import { dynamicSort } from "../utils/dynamic-sort";

const ProjectsMobileContainer = styled.div`
  position: relative;
  width: 100%;

  & .list-view,
  & .image-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    transition: 300ms transform ease;
  }

  & .list-view {
    transform: translateX(-100vw);
  }

  & .image-view {
    transform: translateX(100vw);
  }

  &.active-view-list {
    & .list-view {
      transform: translateX(0);
    }
  }

  &.active-view-image {
    & .image-view {
      transform: translateX(0);

      & .single-project-container {
        margin: 0 0 20px 0;

        & a {
          display: block;

          & img {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
`;

export const ProjectsMobile = ({ data, activeSort, activeView }) => {
  const sortedProjects = data.allPrismicProject.nodes.sort(
    dynamicSort(activeSort)
  );

  const listViewProjects = sortedProjects.map((project, index) => (
    <li className="single-project-container" key={`single_project_${index}`}>
      <Link to={project.url}>
        <span className="title">{project.data.title.text}</span>
        <span className="year">{project.data.year}</span>
      </Link>
    </li>
  ));

  const imageViewProjects = sortedProjects.map((project, index) => (
    <li className="single-project-container" key={`single_project_${index}`}>
      <Link to={project.url}>
        <img
          srcSet={project.data.thumbnail.fluid.srcSet}
          src={project.data.thumbnail.fluid.src}
          alt={project.data.thumbnail.alt}
        />
        <span className="title">{project.data.title.text}</span>
      </Link>
    </li>
  ));

  return (
    <ProjectsMobileContainer className={`active-view-${activeView}`}>
      <div className="list-view">
        <ol>{listViewProjects}</ol>
      </div>

      <div className="image-view">
        <ol>{imageViewProjects}</ol>
      </div>
    </ProjectsMobileContainer>
  );
};
